<template>
    <members-index
        :include-fields="['name', 'email', 'createdAt', 'roles', 'status', 'actions']"
        :role="role"
        :title="$t('nav.users.index')"
    >
        <template #header>
            <role-dropdown
                v-model="role"
                allow-deselect
                class="mr-3"
            />
        </template>
    </members-index>
</template>

<script>
import MembersIndex from '@/pages/shared/Members';
import RoleDropdown from '@/components/users/RoleDropdown';

export default {
    name: 'UsersIndex',
    components: {MembersIndex, RoleDropdown},
    data() {
        return {
            role: undefined,
        };
    },
};
</script>
